import { Component, OnInit, Input, EventEmitter,Output } from '@angular/core';
import { AppService } from 'src/app/app.global';
import { LivelocationService } from 'src/app/@core/services/livelocation.service';

@Component({
  selector: 'app-gps-lost-table',
  templateUrl: './gps-lost-table.component.html',
  styleUrls: ['./gps-lost-table.component.scss']
})
export class GpsLostTableComponent implements OnInit {
  @Input() gpsdata:any;
  @Input() loadergps:any;
  @Input() frompage:any;
  @Output() closepanel      =  new EventEmitter();
  page = 1;
  pageSize: any = 20;
  searchString:any='';
  deleteMsg = "";
  deleteToggle = false;
  constructor(
    public appService: AppService,
    private livelocationService : LivelocationService,
  ) { }

  ngOnInit(): void {

  }
  closePanel(){
    this.closepanel.emit();
  }

  notifyemployee(id:any){
    this.livelocationService.notifyemp(id,this.frompage).subscribe((res: any) => {
      if(res.body.status == "0"){
        this.deleteMsg = res.body.message
        this.deleteToggle = true
      }  else{
       
      }
    })
  }

}
