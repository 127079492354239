import { Component, OnInit ,Input,Output,EventEmitter, Inject, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import { FamilyService } from 'src/app/@core/services/family.service';
import { FormBuilder,Validators ,FormArray,FormGroup} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { MessageService } from 'src/app/message.global';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FamilyComponent implements OnInit {
  showSidePanel         = false;
  showSidePanelchild    = false;
  showSidePaneldepend   = false;
  loader                = true;
  deleteToggle          = false;
  isSingle              = true;
  familySubmitted       = false;
  childSubmitted        = false;
  dependentSubmitted    = false;
  isrequired            = false;
  childData:any         = [];
  dependData:any        = [];
  currentYear:number    = 0;
  gender:string         = "gender";
  marital_status:string = "marital_status";
  StatusPlaceHolder     = this.messageService.selectplaceholddisplay('marital status');
  genderPlaceHolder     = this.messageService.selectplaceholddisplay('gender');
  relationPlaceHolder   = this.messageService.selectplaceholddisplay('relation');
  relation:string       = "relation";
  childHead             = "";
  dependentHead         = "";
	deleteText            = "";
	familyHeader:any      = "";
  loader1               = false;
  childloader           = false;
  dependloader          = false;
  deleteClicked         = false;
  permissions:any       = [];
  currentChildIndex:any = '';
  currentDepIndex:any   = '';
  childId:any;
  dependId:any;
  showInfo  = false;
  infoMsg:any;
	@Input() familyData:any ;
	@Input() id:any ;
	@Input() loginAs:any ;
  @Input() is_employeeRequest:any;
  @Input() from:any;
  @Input() fieldPermission:any;
  @Input() addEditPermission:any;
  @Output() loadData            = new EventEmitter();
  @Output() saveOrUpdateFamily  = new EventEmitter();
  @Input() routingUrl:any;

  // FAMILY FORM WITH SINGLE FORM
    completeFamily            = this.formBuilder.group({
                                  id                        : 0,
                                  employee                  : 0,
                                  father_name               : ['', [Validators.required,NamefieldValidator(),Validators.maxLength(100)]],
                                  father_date_of_birth      : [null],
                                  mother_name               : ['', [NamefieldValidator(),Validators.maxLength(100)]],
                                  mother_date_of_birth      : [null],
                                  marital_status            : [],
                                  date_of_marriage          : [null],
                                  spouse_name               : [null, [NamefieldValidator(),Validators.maxLength(100)]],
                                  spouse_date_of_birth      : [null],
                                  child                     : this.formBuilder.array([]),
                                  dependant                 : this.formBuilder.array([]),



    });
  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService, private familyService:FamilyService,public messageService : MessageService,
    private formBuilder:FormBuilder,private datepipe:DatePipe,private snapshot:SnapshotService, private notificationService:NotificationService,public changeDetectorRef: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.getPermission();
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);

    this.currentYear  = new Date().getFullYear();
    this.loader       = false;
    this.childData    = this.familyData.children;
    this.dependData   = this.familyData.dependent_detail;
    if( this.familyData.marital_status !='Single')
      this.isSingle   = false;
    this.loadData.emit();


  }
  getPermission(){
    this.permissions = this.appservice.getPermissions(this.routingUrl);
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  get f()  { return this.completeFamily.controls; }/*Family form control*/

  config = { format: this.appservice.getdateformat(),

          };/*Format datepicker*/


  getFamilyData(){
    this.loader1          = true;
    this.familyService.getFamilylData(this.id).subscribe((res:any)=>{

      if(res.family.length>0){
        this.familyData   = res.family[0];
        this.childData    = this.familyData.children;
        this.dependData   = this.familyData.dependent_detail;

        Object.entries( this.familyData ).forEach(([key, value]) => {


             if((key=='father_date_of_birth' || key =='mother_date_of_birth' || key=='date_of_marriage' || key=='spouse_date_of_birth') && value!=null){
              let finalDate = value;
              this.completeFamily.get(key)?.setValue(finalDate);

            }
            else {
              this.completeFamily.get(key)?.setValue(value);

            }

        })
        for(let i=0;i<this.childData.length;i++){
          this.child().removeAt(i);
          this.addChild();
          Object.entries( this.childData[i] ).forEach(([key, value]) => {

            this.child().controls[i].get(key)?.setValue(value);

          })
        }
        for(let i=0;i<this.dependData.length;i++){
          this.dependant().removeAt(i);
          this.addDependant();
          Object.entries( this.dependData[i] ).forEach(([key, value]) => {

            this.dependant().controls[i].get(key)?.setValue(value);

          })
        }

      }
      this.loader1          = false;
      this.changeDetectorRef.detectChanges();

    })

  }


  editFamily(event:any){
    this.completeFamily.markAsUntouched()
    if(this.is_employeeRequest?.is_family_data_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo         = false;
      this.familyHeader     = event;
      this.showSidePanel    = true;
      this.loader1          = true;
      this.familySubmitted  = false
      // this.familyForm.reset();
      this.completeFamily.reset();
      if(event=='Add'){
      this.completeFamily.get('id')?.setValue(0);

      }
      this.completeFamily.get('employee')?.setValue(this.id);
      this.getFamilyData();
      // this.loader1        = false;
    }
  }
  deleteChildSlider(index:any,id:any){
    if(id!==null && id!==''){
      this.currentChildIndex  = index;
      this.deleteChild(id)
    }
    else
    this.deleteSingleChild(index);

  }
  deleteDependSlider(index:any,id:any){
    if(id!==null && id!==''){
      this.currentDepIndex  = index;
      this.deleteDependent(id)
      }
      else
      this.deleteSingleDependant(index);
    }

  deleteChild(id:number){
    this.deleteClicked = false
    this.deleteToggle = true;
    this.deleteText   = "child";
    this.childId      = id;/*child id for delete*/

  }

  deleteChildConfirm(){
    this.deleteClicked = true

    this.familyService.deleteChildData(this.childId).subscribe((res:any)=>{
      // if(this.currentChildIndex==='' || this.currentChildIndex===null)
      this.getFamilyData();
      this.deleteToggle       = false;
      this.deleteSingleChild(this.currentChildIndex);
      this.currentChildIndex  = ''
      this.changeDetectorRef.detectChanges();

    })


  }
  deleteDependent(id:number){
    this.deleteClicked = false
    this.deleteText   = "dependency";
    this.deleteToggle = true;
    this.dependId     = id;

  }
  deleteDependConfirm(){
    this.deleteClicked = true
    // if(this.currentDepIndex!='' && this.currentDepIndex!=null)
    // this.deleteSingleDependant(this.currentDepIndex);

    this.familyService.deleteDependentData(this.dependId).subscribe((res:any)=>{
      // if(this.currentDepIndex==='' || this.currentDepIndex===null)
      this.getFamilyData();
      this.deleteToggle         = false;
      this.deleteSingleDependant(this.currentDepIndex);
      this.currentDepIndex      = '';
      this.changeDetectorRef.detectChanges();

    })
  }



  onSubmit(){
    if(this.completeFamily.invalid){

      for(let i in this.completeFamily.controls)
        this.completeFamily.controls[i].markAsTouched();
      return;
    }
    else
    this.familySubmitted  =  true;

    this.showSidePanel    = false;

    if(this.completeFamily.value.father_date_of_birth!=null){
      let finalDate         = this.appservice.dateFormatConvert(this.completeFamily.value.father_date_of_birth);
      this.completeFamily.get('father_date_of_birth')?.setValue(finalDate)
    }
    if(this.completeFamily.value.mother_date_of_birth!=null){
      let finalDate         = this.appservice.dateFormatConvert(this.completeFamily.value.mother_date_of_birth);
      this.completeFamily.get('mother_date_of_birth')?.setValue(finalDate)
    }
    if(this.completeFamily.value.date_of_marriage!=null){
      let finalDate         = this.appservice.dateFormatConvert(this.completeFamily.value.date_of_marriage);
      this.completeFamily.get('date_of_marriage')?.setValue(finalDate)
    }
    if(this.completeFamily.value.spouse_date_of_birth!=null){
      let finalDate         = this.appservice.dateFormatConvert(this.completeFamily.value.spouse_date_of_birth);
      this.completeFamily.get('spouse_date_of_birth')?.setValue(finalDate)
    }
    for(let i=0;i<this.child().length;i++){
      let child_date_of_birth   = this.child().controls[i]?.value.child_date_of_birth;
      let finalDate             = this.appservice.dateFormatConvert(child_date_of_birth);
      this.child().controls[i].get('child_date_of_birth')?.setValue(finalDate)
    }
    for(let i=0;i<this.dependant().length;i++){
      let dependent_of_birth    = this.dependant().controls[i]?.value.dependent_of_birth;
      let finalDate             = this.appservice.dateFormatConvert(dependent_of_birth);
      this.dependant().controls[i].get('dependent_of_birth')?.setValue(finalDate)
    }

    let basicFamData:any;
    basicFamData= {
        'id'                    : this.completeFamily.value.id!=0?this.completeFamily.value.id:null,
        'employee'              : this.id,
        'father_name'           : this.completeFamily.value.father_name,
        'father_date_of_birth'  : this.completeFamily.value.father_date_of_birth,
        'mother_name'           : this.completeFamily.value.mother_name,
        'mother_date_of_birth'  : this.completeFamily.value.mother_date_of_birth,
        'marital_status'        : this.completeFamily.value.marital_status,
        'date_of_marriage'      : this.completeFamily.value.date_of_marriage,
        'spouse_name'           : this.completeFamily.value.spouse_name,
        'spouse_date_of_birth'  : this.completeFamily.value.spouse_date_of_birth,
    };
    let tempChild   = this.completeFamily.getRawValue().child;
    let tempDepend  = this.completeFamily.getRawValue().dependant;
    if(this.loginAs=='Employee'){

      basicFamData.old_object = this.completeFamily.value.id;
      delete (basicFamData.id);
      delete (basicFamData.employee);
      for(let i=0;i<tempChild.length;i++)
      {
        tempChild[i].old_object = tempChild[i].id!=null?tempChild[i].id:0;
        delete (tempChild[i].id);

      }
      for(let i=0;i<tempDepend.length;i++)
      {
        tempDepend[i].old_object = tempDepend[i].id!=null?tempDepend[i].id:0;
        delete (tempDepend[i].id);

      }

    }

    let personalFam     = {'family':basicFamData};
    let childrenData    = {"child":tempChild};
    let dependData      = {"dependant":tempDepend};
    let mergedData      = { ...personalFam, ...childrenData, ...dependData};

    // if(this.completeFamily.value.id!=0){

      if(this.loginAs=='Employee'){

        let tempData = {
          "temp_data": "family_data",
          "data"     : mergedData,
        }
        this.snapshot.updateTempEmployeedata(tempData).subscribe((res:any)=>{
          if(res.employee_request == 0)
          {
            this.notificationService.handleErrorNotification("Your previous request is pending, you cannot raise a new request.","Error");
          }
          this.getFamilyData();
          this.showSidePanel = false;
        })


      }


        this.saveOrUpdateFamily.emit(mergedData);

  }






  disableDate(){
    return false;
  }
  dobFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  /*CREATE CHILD DATA*/
  createChild():FormGroup{

    return this.formBuilder.group({
                                    id                  : null,
                                    family              : null,
                                    child_name          : ['', [Validators.required,NamefieldValidator(),Validators.maxLength(100)]],
                                    child_date_of_birth : [null,Validators.required],
                                    child_gender        : [,Validators.required],
                                    is_dependent        : [false],
                                    is_going_to_hostel  : [false],
                                    is_going_to_school  : [false],
                                  })

  }

  child() {
    return this.completeFamily.get('child') as FormArray;
  }
  addChild() {
    this.child().push(this.createChild());
  }
  deleteSingleChild(index:any){
    this.child().removeAt(index);

  }
  /*DEPENDANCY*/
  createDependancy():FormGroup{

    return this.formBuilder.group({
                                        id                        : null,
                                        family                    : null,
                                        dependent_name            : ['', [Validators.required,NamefieldValidator(),Validators.maxLength(100)]],
                                        dependent_of_birth        : [null,[Validators.required]],
                                        dependent_gender          : [,[Validators.required]],
                                        dependent_relation        : [,[Validators.required]],
                                        dependent_remark          : ['',Validators.maxLength(500)]
                                })

  }

  dependant() {
    return this.completeFamily.get('dependant') as FormArray;
  }
  addDependant() {
    this.dependant().push(this.createDependancy());
  }
  deleteSingleDependant(index:any){
    this.dependant().removeAt(index);

  }
  addNewFamilydata(){
    this.completeFamily.reset()
    this.completeFamily.markAsUntouched()
    this.familySubmitted =false
    if(this.is_employeeRequest?.is_family_data_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
    this.showSidePanel    = true;
    }

  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }

}
