import { Component, OnInit,Input,Output,EventEmitter, Inject } from '@angular/core';
import { EducationService } from 'src/app/@core/services/education.service';
import { FormBuilder,Validators ,FormArray,FormGroup} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.global';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MessageService } from 'src/app/message.global';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { CgpaValidator } from 'src/app/@shared/validators/cgpa.validators';
@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  showSidePanel       = false;
  loader              = true;
  submitted           = false;
  isError             = false;
  errorMessage        = false;
  showSidePanelCreate = false;
  createSubmit        = false;
  deleteToggle        = false;
  deletedEduId        = 0;
  currentYear:number  = 0;
  loader1             = false;
  permissions:any     = [];
  deleteClicked = false
  heading = 'Add'
  deleteIndex : any
  editStatus = false;
  currentId:any;
  educationForm       = this.formBuilder.group({
                            education:this.formBuilder.array([])
                        });

  @Input() educationData:any;
  @Input() id:any;
  @Input() is_employeeRequest:any;
  @Input() addEditPermission:any;
  @Output() editEducationalSubmit  = new EventEmitter();
  @Output() createEducationSubmit  = new EventEmitter();
  @Output() deleteEducationSubmit  = new EventEmitter();
  @Output() loadData               = new EventEmitter();
  @Input() routingUrl:any;
  @Input() loginAs :any;
  @Input() from:any;
  @Input() fieldPermission:any;
  showInfo  = false;
  infoMsg:any;

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,private educationService:EducationService,private formBuilder: FormBuilder,public messageService : MessageService, private datePipe:DatePipe) { }

  ngOnInit(): void {
    this.getPermission();
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);

    this.loader       = false;
    this.currentYear  = new Date().getFullYear();
    this.loadData.emit();
  }
  getPermission(){
    this.permissions = this.appservice.getPermissions(this.routingUrl);
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getEducationData(){
    this.educationService.getEducationData(this.id).subscribe((res:any)=>{
      if(res.education.length>0)
      this.educationData = Object.values(res.education);

    })
  }

  education() {
    return this.educationForm.get('education') as FormArray;
  }
  createEducation():FormGroup{

    return this.formBuilder.group({
                            qualification      :['',[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                            specialization     :['',[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                            year_of_passing    :[null],
                            university_board   :[null,[Validators.maxLength(100),UnamepatternValidator()]],
                            college_school     :['',[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                            grade_cgpa         :[null,[CgpaValidator(),Validators.maxLength(5)]],
                            employee           :[this.id],
                            id                 :[null],

    })
  }

  addEducation() {
    this.education().push(this.createEducation());
  }

  editEducation(){
    this.educationForm.markAsUntouched()
    this.heading = 'Edit'
    if(this.is_employeeRequest?.is_education_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);

    }else{
      this.showInfo            = false;
      this.loader1             = true;
      this.submitted           = false;
      this.getEducationData();
      let educationArr = [];
      for(let key=0;key<this.educationData.length;key++){
        this.education().removeAt(key);
        this.addEducation()
        educationArr.push({
         "qualification":this.educationData[key].qualification,
         "specialization":this.educationData[key].specialization,
         "university_board":this.educationData[key].university_board,
         "id":this.educationData[key].id,
         "employee":this.educationData[key].employee,
         "college_school":this.educationData[key].college_school,
         "grade_cgpa":this.educationData[key].grade_cgpa,
         "year_of_passing":this.educationData[key].year_of_passing,

       });
    }

    this.educationForm.reset({
      education : educationArr
    })
    this.showSidePanelCreate       = true;
      this.loader1 = false;
    }
  }

  getFirstChar(name:string,num:number){
   if(name!=null){
    let newVal = name.replace(/[^\w\s]/gi, '')
    if(newVal.split(' ').length == 1){
      return newVal.substring(0, num);
    }else
      {
        return JSON.stringify(name.match(/\b(\w)/g)).replace(/[^A-Za-z]/g, '').substring(0, num);
      }
    }
    else
    return
  }

  addNewEducation(){
    this.educationForm.reset()
    this.educationForm.markAsUntouched()
    if(this.is_employeeRequest?.is_education_requested){
      this.showInfo = true;
      this.infoMsg  = this.messageService.requestRestriction(this.loginAs);
    }else{
      this.showInfo            = false;
      this.showSidePanelCreate = true;
      this.createSubmit        = false;
      if(this.education().length == 0){
        this.addEducation()
      }
    }
  }
  onCreateSubmit(){
    if(this.education().invalid){
      for(let i in this.education().controls)
        this.education().controls[i].markAsTouched();
      return;
    } else
    this.createEducationSubmit.emit(this.education().value);
    this.showSidePanelCreate  = false;
  }

  deleteEducation(index:number,id:any){

    if(id!=null && id!=''){
      this.deleteToggle = true;
      this.deletedEduId = this.educationData[index].id;
      this.currentId    = index;
    }
    else{
      this.education().removeAt(index);

    }
    // this.deleteIndex = index;
  }

  deleteEducationConfirm(){
    this.deleteClicked  = true
    this.deleteToggle   = false;
    this.education().removeAt(this.currentId);

    this.deleteEducationSubmit.emit(this.deletedEduId);
  }

  disableDate(){
    return false;
  }
  dateFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  closeInfo(bool:any){
    this.showInfo = false;
  }

  getFieldPermission(fieldName:any){
    if((this.from=='myteam' && this.fieldPermission?.indexOf(fieldName)) != -1  || (this.from != 'myteam')){
      return true;
    }else{
      return false;
    }
  }
}
