import { Component, OnInit,Output,EventEmitter, Input} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { MessageService } from 'src/app/message.global';
import { GstnumValidator } from 'src/app/@shared/validators/gstnum.validators';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';

@Component({
  selector: 'app-reimbursement-details',
  templateUrl: './reimbursement-details.component.html',
  styles: [
  ]
})
export class ReimbursementDetailsComponent implements OnInit {
  @Input()formGp : FormGroup = this.fb.group({
                                reimbursement  : []
  })
  @Input()categorySummaryData:any;

  @Output() submitmethod        = new EventEmitter();

  submitted                     =  false;
  docLoadersArray :any          = [];
  fileType: string              = ".png,.jpeg,.jpg,.zip,.docx,.xlsx,.doc,.dotx,.pdf";
  errormsg:any;
  doj:any;
  currentControl:any;
  constructor(
    public fb:FormBuilder,
    public messageService : MessageService,
    public appservice:AppService,
    public SnapshotService:SnapshotService,    
    ) { }

  ngOnInit(): void {
    let userData        = this.appservice.getEmpData();
    this.SnapshotService.getEmployeeData(userData?.employee_id).subscribe((res:any)=>{
      this.doj=res?.date_of_joined;
    });
    if(this.reimbursementreq().length==0){

      this. addReimbursementreq();

    }
    else
    {
      if(this.docLoadersArray.length==0)
      this.stepperDocData();
    }

  }
  reimbursementreq() : FormArray {
    return this.formGp.get("reimbursement") as FormArray
  }
  newReimbursementReq(): FormGroup {
    return this.fb.group({
      "claim_date"  : [],
      "bill_date"   : ['',Validators.required],
      "bill_no"     : ['',[Validators.required, Validators.maxLength(20),GstnumValidator()]],
      "bill_amount" : ['',[Validators.required,digitdecimalValidator(),Validators.maxLength(10)]],
      "document"    : ['',[Validators.required]],
      "description" : ['',[Validators.maxLength(500)]]
    })
  }
  addReimbursementreq() {
    this.reimbursementreq().push(this.newReimbursementReq());
    this.defaultClaimDate();
  }

  removeReimbursementreq(i:number) {
    this.reimbursementreq().removeAt(i);
    this.docLoadersArray.splice(i,1);
  }
  disableDate(){
    return false;
  }
  changeTab(){
    this.submitted   =  true;
    this.submitmethod.emit(3);

  }
  defaultClaimDate(){
    let claimData        = new Date();

    let counter          = this.reimbursementreq().length-1;
    for(let i=0;i<this.reimbursementreq().length;i++){
      this.reimbursementreq().controls[i].get('claim_date')?.setValue(claimData);
      this.reimbursementreq().controls[i].get('claim_date')?.disable();

    }
    this.docLoadersArray.push({['loader'+counter]:false,['filesize'+counter]:false});

  }
  stepperDocData(){
    let tempdata = this.docLoadersArray;
    this.docLoadersArray = [];
    for(let i=0;i<this.reimbursementreq().length;i++){
      let loader  = tempdata.loader+i;
      let size    = tempdata.filesize+i;
      this.docLoadersArray.push({['loader'+i]:loader,['filesize'+i]:size});

    }

  }
  setCurrentControl(control:any){
    this.currentControl = control;
  }
  getURL(event:any){
    this.reimbursementreq().controls[this.currentControl].get('document')?.setValue(event);
    this.docLoadersArray[this.currentControl]['loader'+this.currentControl]   = false;
    this.docLoadersArray[this.currentControl]['filesize'+this.currentControl] = false;


  }
  getloader(event:any){

    this.docLoadersArray[this.currentControl]['loader'+this.currentControl] = true;

  }

  getvalidsize(event:any){

    this.docLoadersArray[this.currentControl]['filesize'+this.currentControl] = true;
    this.errormsg = event;

  }
  deleteDoc(index:any){
    this.reimbursementreq().controls[index].get('document')?.setValue('');

  }


  billFilter(d:any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }

  // validateBillAmount(index:any){
  //   let current_value          = Number(this.reimbursementreq().controls[index]?.get('bill_amount')?.value);
  //   let balance                = Number(this.categorySummaryData[0].balance);
  //   let total_amount :Number   = 0;

  //   for(let i=0;i<this.reimbursementreq().length;i++){
  //     total_amount  = Number(total_amount)+ Number(this.reimbursementreq().controls[i]?.get('bill_amount')?.value);
  //   }


  //   if(Number(total_amount) > balance){

  //     let permittedAmount = balance - (Number(total_amount) - current_value);
  //     if(permittedAmount<=0)
  //     permittedAmount = 0;
  //     this.reimbursementreq().controls[index].get('bill_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(10),Validators.max(permittedAmount)])
  //   }
  //   else{

  //     this.reimbursementreq().controls[index].get('bill_amount')?.clearValidators()
  //     this.reimbursementreq().controls[index].get('bill_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(10)])
  //   }
  //   this.reimbursementreq().controls[index].get('bill_amount')?.updateValueAndValidity();

  // }

  // test start

  modalToggle                     = false;
  allowedExtension                = "";
  alertMsg:any                    = '';
  alertToggle                     = false;
  fileName                        = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;
   /********* VIEW DOCUMENT FUNCTION ****************/
   viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appservice.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle          = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }
  // test end
  billdatefn(i:any){
    
    let key1 = moment(this.reimbursementreq().controls[i].get('bill_date')?.value).format('YYYY-MM-DD');
    let key2 = moment(this.doj).format('YYYY-MM-DD');
    if((moment(key1,'YYYY-MM-DD')).isBefore(moment(key2,'YYYY-MM-DD'))){
      this.reimbursementreq().controls[i].get('bill_date')?.setErrors({
        'beforedoj' : true
      })
    }else if(this.reimbursementreq().controls[i].get('bill_date')?.errors?.required == false){
      this.reimbursementreq().controls[i].get('bill_date')?.setErrors(null)
    }else if(this.reimbursementreq().controls[i].get('bill_date')?.errors?.beforedoj == true){
      this.reimbursementreq().controls[i].get('bill_date')?.setErrors({
        'beforedoj' : false
      })
    }
  }
}
