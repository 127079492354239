import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { Router } from '@angular/router';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { AppService } from 'src/app/app.global';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { NamefieldValidator } from 'src/app/@shared/validators/namefield.validators';
import { EmailinputValidator } from 'src/app/@shared/validators/emailinput.validators';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { EmpcodeValidator } from 'src/app/@shared/validators/empcode.validators';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { debounceTime,  delay,switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { phoneNumberValidator } from 'src/app/@shared/validators/phoneno.validators';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  viewToggle="grid"
  searchKeyword="";
  sendPassword         : boolean = false
  currentFile?: File;
  selectedFiles?: FileList;
  API_PATH: string = environment.apiPath;
  url = `${this.API_PATH}organization/api/v1/employee-excel-download/`;
  progress              = 0;
  message               = '';
  showSidePanel         = false;
  phoneCode:any[]       = [];
  currentYear:number    = 0;
  employeeForm:any;
  submitted             = false;
  imageUrl: any         = "";
  infinityloader        = false;
  emp:any[]             = [];
  statusList:any        = [];
  itemsList:any[]       = [];
  defaultlimit:any      = 20;
  offset                = 0;
  ordering              = '';
  loader                = true;
  direction             = '';
  listlength            = 0;
  matrixDropdown:any[]  = [];
  status:any            = true;
  nodata                = false;
  searchlistdata:any    = [] ;
  gradeDD :any[]        = [];
  BranchDD :any[]       = [];
  DesignationDD :any[]  = [];
  DepDD  :any[]         = [];
  buDD  :any[]          = [];
  companyDD :any[]      = [];
  empDD :any[]        = [];
  codeError:any         = "";
  phnError:any          = "";
  mailError:any         = "";
  permissions:any       = [];
  errormsg:any          = "";
  invalidFile           = false;
  filter                      = false;
  filterscroll =false;
  filterdata:any = this.employeeService.FILTERDATA
  listCount = 0
  page = 1

  empReportStatus : any
  empFunctionStatus : any

  filterForm                = this.formBuilder.group({
    company               : '',
    bu                    : '',
    department            : '',
    designation           : '',
    grade                 : '',
    emp_status            : '',
    emp_type              : '',
    branch                : '',
    repomanager           :'',
    funmanager            :'',
    sortby                : ['All'],
  }
);
maxdoj = new Date(new Date().getFullYear() + 2, 11, 31)
response:any;
from = 'Employee';

  @ViewChild(SearchbarComponent) child:any;
  searchkey = this.messageService.searchdisplay('Employee');
  prefixkey = this.messageService.selectplaceholddisplay('Prefix');
  genderkey = this.messageService.selectplaceholddisplay('Gender');
  bloodgrpkey = this.messageService.selectplaceholddisplay('Blood Group');
  natkey = this.messageService.selectplaceholddisplay('Nationality');
  emptypekey = this.messageService.selectplaceholddisplay('Employment type');
  empstakey = this.messageService.selectplaceholddisplay('Employment status');
  skillkey = this.messageService.selectplaceholddisplay('Skill type');
  datedob:any = new Date();

  validated : boolean = false
  employeeUnit : any = []
  field : string = ''
  allowedEmpStatus = ['Resigned','Relieved','Settled','Terminated'];
  searchData : Subject<void> = new Subject();
  empId = 0
  private subjectKeyUp = new Subject<any>();

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private employeeService : EmployeeService,
    private formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public router: Router,
    public appservice:AppService,
    public messageService : MessageService,
    private existDataService : ExistDatasService,
    private cd :  ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subjectKeyUp.pipe(debounceTime(900)).subscribe((d:any)=>{
      this.existService(d.fieldName,d.fieldValue)
    })

    let arrFilter: any = []
    let arrList = []
    let arrData = []
    if(this.filterdata){
      arrData = this.filterdata.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })


      let ordering:any = this.appservice.memOrdering()
      this.filterForm.reset(this.appservice.memFilterArray(arrFilter,ordering));
    }

    this.datedob.setDate( this.datedob.getDate() - 6 );
    this.datedob.setFullYear( this.datedob.getFullYear() - 14 );
    this.getPermission();
    this.getDateFormat();

    this.currentYear = new Date().getFullYear();
    this.offset = 0;
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;

      }
    });

    // this.getList();
    this.applyfilterfn();
    // this.getStatusList();
    this.employeeForm = this.formBuilder.group({
      id                    : 0,
      profile_image         : [''],
      prefix                : [null, Validators.required],
      first_name            : [null, [NamefieldValidator(),Validators.maxLength(100),Validators.required]],
      middle_name           : [null, [NamefieldValidator(),Validators.maxLength(100)]],
      last_name             : [null, [NamefieldValidator(),Validators.maxLength(100)]],
      do_birth              : [null, Validators.required],
      gender                : [null, Validators.required],
      blood_group           : [null],
      email                 : [null, [Validators.required,EmailinputValidator()]],
      nationality           : [null, Validators.required],
      phone_code            : [null],
      phone_number          : [null, [Validators.required, NumberpatternValidator()]],
      do_join               : [null, Validators.required],
      emp_code              : [null,[Validators.required,EmpcodeValidator()]],
      emp_type              : [null, Validators.required],
      emp_status            : [null, [Validators.required,Validators.pattern]],
      do_confirmation       : [null],
      reporting_manager     : [null],
      functional_manager    : [null],
      company               : [null, Validators.required],
      business_unit         : [null, Validators.required],
      department            : [null, Validators.required],
      designation           : [null, Validators.required],
      branch                : [null, Validators.required],
      grade                 : [null, Validators.required],
      skill_type            : [null, Validators.required]
    }, {
      validators: phoneNumberValidator('phone_code', 'phone_number')
    });

    this.searchData.pipe(delay(1000),
      switchMap(() =>  this.employeeService.searchResults(this.defaultlimit,this.offset,this.searchKeyword,this.filterdata,this.empId)
    )).subscribe((result: any) => {
      if(result.results)
        this.salarygetfn1(result);
      else if(result.length == 1){
        this.emp = [];
        this.itemsList= result;
        this.addItems(1);
        this.nodata = false;
        this.loader = false;
      }
    });
  }

  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }
  getPermission(){
    this.permissions = this.appservice.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }
  profileEdit(id:any){
    if(this.permissions)
    this.router.navigate(['/employee/employee-hr-edit/'+id]);
  }

  get f()  { return this.employeeForm.controls; }

  getFormPrefillData(){
    this.employeeForm.reset();
    this.getCompanyList();
    this.getDepList();
    this.getDesignationList();
    this.getBranchList();
    this.getGradeList();
    // this.getPhonecode();
    // this.getNewEmpCode();
    this.getManagerList();
    this.submitted  =  false;
    this.imageUrl   = "";
    this.mailError  = "";
    // Forcefully setting value for phone code
    this.employeeForm.get('phone_code')?.setValue('91')
  }
  getStatusList(){
    this.employeeService.employeeStatusList().subscribe((res: any) => {
          this.statusList = res.data;
    });
  }

  getPhonecode(){
    this.employeeService.getCountryCode().subscribe((res:any)=>{
      this.phoneCode  = res;
    })
  }
  getCompanyList(){
    this.employeeService.getCompanyList().subscribe((res: any) => {
      this.companyDD = res;
    });
  }
  getBUListFunction(){
    this.employeeForm.get('business_unit')?.reset();
    this.buDD = [];
    if(this.employeeForm?.value?.company != null){
      this.getBUList();
    }
  }

  getBUList(){
    // this.employeeForm.get('business_unit').setValue(null);
    this.employeeService.getBuListCompany(this.employeeForm.value.company).subscribe((res: any) => {
          this.buDD = res;
    });
  }

  getDepList(){
    this.employeeService.getDepartmentList().subscribe((res: any) => {
          this.DepDD = res;
    });
  }

  getDesignationList(){
    this.employeeService.getDesignationList().subscribe((res: any) => {
          this.DesignationDD = res;
    });
  }
  getBranchList(){
    this.employeeService.getBranchList().subscribe((res: any) => {
          this.BranchDD = res;
    });
  }

  getGradeList(){
    this.employeeService.getGradeList().subscribe((res: any) => {
          this.gradeDD = res;
    });
  }
  getManagerList(){
    this.employeeService.managerList().subscribe((res: any) => {
          this.empDD = res;
          let len = this.empDD.length
          for (let i = 0; i < len; i++) {
          this.empDD[i]['fullname']  = "";
          if(this.empDD[i]['first_name'] != null)
           this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
          if(this.empDD[i]['middle_name'] != null && this.empDD[i]['middle_name'] != '')
           this.empDD[i]['fullname'] += this.empDD[i]['middle_name']+" ";
          if(this.empDD[i]['last_name'] != null && this.empDD[i]['last_name'] != '')
           this.empDD[i]['fullname'] += this.empDD[i]['last_name'];
           this.empDD[i]['fullname']   +=" ("+this.empDD[i]['employee_code']+")";

          }

    });
  }
  // getNewEmpCode(){
  //   this.employeeService.getEmpcode().subscribe((res:any)=>{

  //   this.employeeForm.get('emp_code').setValue(res.employee_code);

  //   })
  // }
  getURL(event: any) {
    this.invalidFile = false;
    this.imageUrl    = event;
    this.errormsg    = "";

    this.employeeForm.get('profile_image').setValue(event);
  }
  deleteProfilePic(event: any) {
    this.errormsg    = "";
    this.invalidFile = false;
    this.employeeForm.get('profile_image').setValue('');
    this.imageUrl = '';

  }

  submitEmployee(){
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }
    this.child.clearBar();
    // this.searchKeyword = ''
    this.saveEmployee();
    let checkBox = document.getElementById("sendPassword") as HTMLInputElement
    checkBox.checked = false
  }

  saveEmployee(){

    var dataArray = {
      "prefix": this.employeeForm.controls.prefix.value,
      "profile_image": this.employeeForm.controls.profile_image.value,
      "user": {"first_name":this.employeeForm.controls.first_name.value,"last_name":this.employeeForm.controls.last_name.value!=null?this.employeeForm.controls.last_name.value:''},
      "employee_code": this.employeeForm.controls.emp_code.value.toUpperCase(),
      "middle_name": this.employeeForm.controls.middle_name.value,
      "date_of_birth": this.appservice.dateFormatConvert(this.employeeForm.controls.do_birth.value),
      "gender": this.employeeForm.controls.gender.value,
      "blood_group": this.employeeForm.controls.blood_group.value,
      "nationality": this.employeeForm.controls.nationality.value,
      "work_email": this.employeeForm.controls.email.value,
      "phone_code": this.employeeForm.controls.phone_code.value!=null?this.employeeForm.controls.phone_code.value:91,
      "mobile_number":this.employeeForm.controls.phone_number.value,
      "date_of_joined": this.appservice.dateFormatConvert(this.employeeForm.controls.do_join.value),
      "employment_type": this.employeeForm.controls.emp_type.value,
      "employment_status": this.employeeForm.controls.emp_status.value,
      "date_of_confirmation":(this.employeeForm.controls.do_confirmation.value!=null && this.employeeForm.controls.do_confirmation.value!='') ?this.appservice.dateFormatConvert(this.employeeForm.controls.do_confirmation.value):null,
      "skill_type": this.employeeForm.controls.skill_type.value,
      "company": this.employeeForm.controls.company.value,
      "businessunit": this.employeeForm.controls.business_unit.value,
      "department": this.employeeForm.controls.department.value,
      "designation": this.employeeForm.controls.designation.value,
      "branch":this.employeeForm.controls.branch.value,
      "grade": this.employeeForm.controls.grade.value,
      "reporting_manager": this.employeeForm.controls.reporting_manager.value,
      "functional_manager": this.employeeForm.controls.functional_manager.value,
      "is_sendemail" : this.sendPassword
    }

    this.employeeService.saveEmployee(dataArray).subscribe((res: any) => {
      this.emp = [];
      this.loader = true;
      this.offset = 0;
      this.applyfilterfn();
      this.showSidePanel =false;
    },
    (error:any)=>{
      Object.entries(error.error).forEach(([key, value]) => {
        let control:any
        if(key=='employee_code'){
          control = 'emp_code'
        }
        else if(key=='work_email'){
         control = 'email'
        }
        else if(key=='mobile_number'){
         control = 'phone_number'
        }

        this.employeeForm.get(control)?.setErrors({
          'exist' : true
        })
      })

    }
    );
  }


   /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    if(this.viewToggle=='list')
      this.emp = []
    for (let i = 0; i < itemlist; ++i) {
      this.itemsList[i]['name']  = "";
      if(this.itemsList[i]['first_name'] != null)
      this.itemsList[i]['name'] += this.itemsList[i]['first_name']+" ";
      if(this.itemsList[i]['middle_name'] != null)
      this.itemsList[i]['name'] += this.itemsList[i]['middle_name']+" ";
      if(this.itemsList[i]['last_name'] != null)
      this.itemsList[i]['name'] += this.itemsList[i]['last_name'];
      this.itemsList[i]['nameShort'] = this.appservice.getFirstChar(this.itemsList[i]['first_name']+" "+this.itemsList[i]['last_name'],2);
      this.emp.push(this.itemsList[i]);
    }

  }

 /********* INIFNITY SCROLL FUNCTION *******************/
 onScrollDown() {
  this.infinityloader = true;
  this.offset += this.defaultlimit;
  if(this.listlength){
    this.applyfilterfn();
    this.loader       = false;
    this.direction    = "down";
  }else{
    this.infinityloader = false;
  }
}


searchresults(event:any){

  this.loader = true;
  this.emp    = [];
  this.empId = 0
  if(event == ""){
    this.searchKeyword  = "";
    this.searchData.next()
  }else{
    this.empId = event
    this.searchData.next()
  }
}

sLoading = false
searchemp(searchKeyword:any){
  this.sLoading = true
  this.loader = true;
  this.offset           = 0;
  this.emp= [];
  this.empId = 0
  this.searchKeyword = searchKeyword;
  if(searchKeyword != ""){
    this.searchData.next()
  }
}

disableDate(){
  return false;
}
getfileformat(event:any){
  this.employeeForm.get('profile_image').setValue('');
  this.imageUrl    = '';
  this.invalidFile = false;
  if(event==false){
    this.invalidFile = true;
    this.errormsg = this.messageService.validateFileFormat('jpeg,png,jpg');

  }

}
getvalidsize(event:any){
  this.employeeForm.get('profile_image').setValue('');
  this.imageUrl    = '';
  this.invalidFile = true;
  this.errormsg    = event;

}
dobFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
    new Date()]
  return (d >= dateRange[0] && d<= dateRange[1])
}
dojFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
  return (d >= dateRange[0] && d<= dateRange[1])
}
docFilter(d:any){
  let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() - 50, 11, 31)]
  return (d >= dateRange[0] && d<= dateRange[1])
}
resultfilterfn(data:any){
  this.filterdata = data;
  this.employeeService.setFilterData(data)
  this.child.clearBar();
  this.response?.unsubscribe()
  this.offset = 0;
  this.applyfilterfn();
}
resetCalled(){
  this.filterdata = '';
}
salarygetfn1(res:any){
  this.sLoading = false
  this.listCount = res.count
  this.itemsList = res.results;
  this.listlength = res.results.length;
  if(this.offset ==0){
    this.emp = [];
  }
  this.infinityloader = false;
  this.addItems(this.listlength);
  this.loader = false;
  this.nodata = res.count==0?true:false;
}

applyfilterfn(){

  this.loader = true;
  this.nodata       = false;

  this.employeeService.getfilterdata(this.status,this.defaultlimit,this.offset,this.searchKeyword,this.filterdata).subscribe((res: any) => {
    this.salarygetfn1(res);
  });


  this.employeeService.employeeStatusListfilter(this.filterdata).subscribe((res: any) => {
    this.statusList = res.data;
  });
  this.filter = false;
 }
  filterclick(){
    this.filter = true;
   }
  closefn(daata:any){
    this.filter = daata;
   }

  existService(fieldName:any,fieldValue:any){
    if(fieldName === 'user__email'){
      this.field = 'email'
    }else if(fieldName === 'mobile_number'){
      this.field = 'phone_number'
    }else if(fieldName === 'employee_code'){
      this.field = 'emp_code'
    }else{
      this.field = fieldName
    }
    const fc = this.employeeForm.get(this.field);
    this.existDataService.getSpecificName({"page":"employee","field":`${fieldName}`,"value": `${fieldValue}`}).subscribe((res:any)=>{
      this.employeeUnit = res.body
      this.loader = false

      if(this.employeeUnit.data ==='invalid'){
        fc?.setErrors({
          'exist' : true
        })
      }
    })
  }

  // For validation of focusout(fieldName,fieldValue)
  offKey(fieldName :any, fieldValue:any){

  }

  // For validation of keyup(fieldName,fieldValue)
  keyUp(fieldName :any, fieldValue:any){

    this.subjectKeyUp.next({fieldName,fieldValue});

  }

  statusChange(){
    let status = this.employeeForm.get('emp_status').value;
    if(status == 'Probation'){
      this.employeeForm.get('do_confirmation')?.reset('')
     }
    if(status!=null && status!=''){

      if((this.allowedEmpStatus.includes(status))){

        this.employeeForm.get('emp_status').setErrors({
          'pattern' : true
        })
      }


    }

  }

  CheckedOrNot(value:any){
    this.sendPassword = value.target.checked
  }

  close(){
    this.showSidePanel = false
    let checkBox = document.getElementById("sendPassword") as HTMLInputElement
    checkBox.checked = false
  }

  pageChanged(event:any){
    this.page = event
    this.offset = (this.page-1)*this.defaultlimit;
    this.applyfilterfn();
  }
  toggleMode(type:any){
    this.page =1
    this.viewToggle = type;
    this.offset = 0
    this.applyfilterfn();
  }

 // Inactive Status
  assignedToChangeFunction(e:any,control:any){
    if(e?.employment_status == 'Relieved' || e?.employment_status == 'Settled' || e?.employment_status == 'Terminated' || e?.employment_status == 'Retired' || e?.employment_status == 'Resigned'){
      control == 'reporting_manager' ? this.empReportStatus = e?.employment_status : this.empFunctionStatus = e?.employment_status
      this.employeeForm?.get(control)?.setErrors({
        'relievedError' : true
      })
    }
  }

  phnNumSelected(val:any){
    this.employeeForm.get('phone_number')?.updateValueAndValidity();
    this.cd.detectChanges()
  }

  hasEmergencyContactError(): boolean {
    const emergencyContactControl = this.employeeForm.get('phone_number');
    return !!(emergencyContactControl && (emergencyContactControl.dirty || emergencyContactControl.touched) && emergencyContactControl.errors);
  }

}
